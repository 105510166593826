<template>
    <div class="detail">
        <breadcrumb :breadcrumbList="breadcrumbListBind"></breadcrumb>
        <div class="content">
            <el-form ref="ruleFormRef" :rules="rule" label-position="left" :model="ruleForm" label-width="180px"
            class="demo-ruleForm" label-suffix="：">
                <el-form-item prop="shopId" label="指挥官商品授权门店:">
                    <el-select v-model="ruleForm.shopId" class="m-2" placeholder="选择门店" @change="getTableData" :disabled="$route.query.id">
                        <el-option v-for="item in shopList" :key="item.shopName" :label="item.shopName" :value="item.shopId" />
                    </el-select>
                </el-form-item>
                <div v-if="getShopServeList&&getShopServeList.length">
                    <el-table :data="getShopServeList" border style="width: 50%">
                        <el-table-column type="index" label="序号" width="80" align="center" />
                        <el-table-column prop="tripartiteServeName" label="美团点评商品" align="center"/>
                        <el-table-column prop="serveId" label="指挥官商品" width="250" align="center">
                            <template #default="scope">
                                <el-input v-model="scope.row.serveName" placeholder="请选择服务" readonly @click="openDiaolog(scope.$index,scope.row)" />
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <el-form-item style="margin-top:100px">
                    <el-button @click="goBack" type="primary">返回</el-button>
                    <el-button @click="saveTargetClick(ruleFormRef)" type="primary">提交</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-dialog v-model="dialogVisible" width="660px" @close="closeDialogVisible">
            <div class="reserve-project-list">
                <div class="reserve-top-form">
                    <el-input
                        class="reserve-el-select"
                        @input="handleSearch"
                        size="small"
                        prefix-icon="search"
                        v-model="searchValue"
                        placeholder="输入名称"
                    />
                </div>
                <div class="serve_flex">
                    <div class="category-list">
                        <template :key="item" v-for="(item, index) in teacherServeList">
                            <div @click="handleTypeClick(item, index)" :class="{ active: optServeId == item.kindId }" class="item">
                                {{ item.kindName }}
                            </div>
                        </template>
                    </div>
                    <div class="shop-list">
                        <div class="titls">
                            <div style="width: 80px;">选择</div>
                            <div style="width: 230px;">服务</div>
                            <div>价格</div>
                            <div>时长</div>
                        </div>
                        <el-scrollbar height="280px" width="520px">
                            <div class="serve-list" v-if="teacherServeInfoList&&teacherServeInfoList.length">
                                <template :key="item" v-for="item in teacherServeInfoList">
                                    <div @click="handleServeClick(item)" class="item">
                                        <div class="left">
                                            <div class="icon" style="width:80px" v-if="item.serveId==serveInfo.serveId">
                                                <el-icon color="#7948ea"><CircleCheckFilled /></el-icon>
                                            </div>
                                            <div class="icon" style="width:80px;opacity: 0;" v-else>
                                                .
                                            </div>
                                            <div class="name">{{ item.serveName }}</div>
                                        </div>
                                        <div class="right">
                                            <div class="checkbox">
                                                <div class="salePrice">¥{{ item.price }}</div>
                                                <div>{{ item.serveLength }}分钟</div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </el-scrollbar>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
  
  <script lang="js">
  import { defineComponent, reactive, ref ,computed , watch } from 'vue'
  import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
  import { breadcrumbListBind } from '../list/config/index.js'
  import {rule} from '../list/config/index.js'
  import { useRouter } from 'vue-router'
  import { useRoute } from 'vue-router'
  import { getShopList } from '@/service/main/target'
  import { getTripartiteServeList , bindServe } from "@/service/main/serve";
  import { getTeacherRankServeList } from "@/service/main/reserve";
  import { ElMessage } from 'element-plus'

  import { useStore } from 'vuex'
  import { COMPANY_ID } from '@/common/constant'
  
  export default defineComponent({
    props: {
  
    },
    components: {
      Breadcrumb
    },
    setup() {
        const store = useStore()
        const route = useRoute()
        const router = useRouter()
        const ruleFormRef = ref('')
        const ruleForm = reactive({
            indicationsItemList: [],
            shopId:''
        })
        const serveInfo = reactive({
            serveName:'',
            serveId:''
        })
        const tableIndex = ref('')
        const optServeId = ref('')
        const getShopServeList = ref([])
        const shopList = ref([])
        const dialogVisible = ref(false)
        const teacherServeList = ref([])
        const teacherServeInfoList = ref([])
        const searchValue = ref('')
        const handleTypeClick = (item, index) => {
            // console.log(item, index);
            let value = item.kindId;
            optServeId.value = value;
            let list = teacherServeList.value
            // console.log(list,item);
            console.log(String(value.length > 0));

            if (String(value).length > 0) {
                list = list.filter(ele => ele.kindId == value)
                // console.log(123123);
            }
            // console.log(list);
            teacherServeInfoList.value = list[0].infos
        }
        const closeDialogVisible = () => {
            dialogVisible.value=false
            serveInfo.serveId=''
            tableIndex.value=''
            serveInfo.serveName=''
        }
        const handleServeClick = (item) => {
            getShopServeList.value[tableIndex.value].serveId=item.serveId
            getShopServeList.value[tableIndex.value].serveName=item.serveName
            serveInfo.serveName=item.serveName
            serveInfo.serveId=item.serveId
            dialogVisible.value=false
        }
        const handleSearch = value => {
            let list = teacherServeList.value[0].infos
            let newList = []
            // console.log(value)
            // console.log(value.trim().length > 0);
            if (value.trim().length > 0) {
                list.forEach(ele => {
                if (ele.serveName.indexOf(value) != -1) {
                    newList.push(ele)
                }
                })
            } else {
                newList = list
            }
            teacherServeInfoList.value = newList
        }
        const saveTargetClick = async (formEl = undefined) => {
            if (!formEl) return
            await formEl.validate(async (valid, fields) => {
                let array = []
                getShopServeList.value.map(item=>{
                    array.push(item)
                })
                let itemList = array.map(item=>{
                    return{
                        tripartiteServeId:item.tripartiteServeId,
                        tripartiteServeName:item.tripartiteServeName,
                        serveId:item.serveId
                    }
                })
                let flag = itemList.every(item=>{ return item.serveId })
                if (!flag) {
                    ElMessage({
                        message: '请补全指挥官商品!',
                        type: 'error',
                    })
                }
                if (valid&&flag) {
                    let res = await bindServe({type:1,itemList})
                    if (res.code == 0) {
                        ElMessage({
                            message: '绑定成功!',
                            type: 'success',
                        })
                        router.back()
                    } else {
                        ElMessage({
                            message: res.msg,
                            type: 'error',
                        })
                    }
                }
            })
        }
        const goBack = () => {
            router.back()
        }
        const getTableData = async(e) => {
            let data = await getTripartiteServeList({shopId:e,type:1})
            getShopServeList.value = data.data
        }
        const openDiaolog =(e,item) => {
            tableIndex.value=e
            if (item.serveId) {
                serveInfo.serveName=item.serveName
                serveInfo.serveId=item.serveId 
            }
            dialogVisible.value=true
        }
        const teacherServeCategoryList = ref([{
            kindId: '',
            kindName: '所有分类',
            infos:[]
        }])
        const getServeCatewgory = async () => {
            const res = await getTeacherRankServeList({})
            let list=[];
            res.data.map(item=>{
                item.infos.map(items=>{
                    list.push(items)
                })
            })
            teacherServeCategoryList.value[0].infos=list
            teacherServeList.value=[...teacherServeCategoryList.value,...res.data]

            teacherServeInfoList.value=teacherServeList.value[0].infos
        }
        const initPage = async () => {
            // let _type
            // route.query.type == 0 ? _type = 9 : _type = route.query.type
            // type.value = _type
            const res = await getShopList(10000)
            shopList.value = res.data.list
            getServeCatewgory()
        }
        initPage()
        return {
            breadcrumbListBind,
            ruleForm,
            saveTargetClick,
            goBack,
            shopList,
            getTableData,
            getShopServeList,
            openDiaolog,
            dialogVisible,
            teacherServeInfoList,
            teacherServeList,
            handleTypeClick,
            handleSearch,
            teacherServeCategoryList,
            optServeId,
            serveInfo,
            closeDialogVisible,
            tableIndex,
            handleServeClick,
            searchValue,
            ruleFormRef
        }
  
    }
  })
  </script>
  
  <style scoped lang="less">
  .content{
    padding: 20px;
    margin: 20px;
    background-color: #fff;
    &:deep(.el-table) {
      th.el-table__cell{
        background-color: rgba(239, 239, 239, 1) !important;
        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }
      .el-table__header-wrapper {
      
        .el-table__cell {

          background-color: rgba(239, 239, 239, 1);
          // padding: 9px 0 !important;
          // border:1px solid rgb(223, 223, 223);

          .cell {
            color: rgba(80, 80, 80, 1) !important;
            font-size: 14px !important;
            font-weight: 500 !important;
          }
        }
      }
    }
  }
  .reserve-project-list {
    .serve_flex{
        display: flex;
    }
    .active {
        background-color: #fff !important;
        color: rgb(133, 88, 250);
        font-weight: 550;
    }
    .category-list {
        width: 100px;

        .item {
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        line-height: 45px;
        background-color: rgb(239, 239, 239);
        border-bottom: 1px solid rgb(255, 255, 255);
        min-width: 100px;
        cursor: pointer;
        }
    }

    .reserve-top-form {
        display: flex;
        justify-content: space-between;
        background-color: rgb(239, 239, 239);
        padding: 10px 20px;

        .reserve-el-select {
        width: 45%;
        }
    }

    &:deep(.serve-list-wrap) {
        padding-right: 10 !important;
    }

    .empty {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
.shop-list {
    width: 520px;
    text-align: center;
    .serve-list {
      .item {
        display: flex;
        height: 40px;
        align-items: center;
        border-bottom: 1px solid #efefef;

        .left {
        //   width: 330px;
          display: flex;
          height: 40px;
          align-items: center;
          .name{
            width: 230px;
          }
        //   .icon{
        //     width: 80px;
        //     display: flex;
        //     justify-content: center;
        //   }
        }

        .right {

          .checkbox {
            display: flex;

            div {
                width: 110px;
            }

            .salePrice {
              color: rgb(255, 105, 74);
            }
          }
        }
      }
    }

    .titls {
      display: flex;
    //   margin-bottom: 20px;

      div {
        width: 110px;
        line-height: 40px;
        background-color: rgb(239, 239, 239);
      }
    }

  }
  </style>
  
  
  
  
  